import React from 'react';

const privacyContent = {
  title: "Privacy Policy",
  intro: "At Peak Health, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose your information.",
  sections: [
    {
      heading: "1. Information We Collect",
      paragraph: "We collect information that you provide to us directly, such as when you register for an account, log your health metrics (glucose levels, medication, exercise, sleep, and food intake), and interact with our app. We also collect information automatically, such as your device information and usage data."
    },
    {
      heading: "2. How We Use Your Information",
      paragraph: "We use your information to provide, maintain, and improve our services. This includes using your information to track your health metrics, generate reports, and communicate with you about your account and our services."
    },
    {
      heading: "3. Sharing Your Information",
      paragraph: "We do not share your personal information with third parties except as necessary to provide our services, comply with the law, or protect our rights. We may share aggregated or anonymized data that does not identify you personally."
    },
    {
      heading: "4. Data Security",
      paragraph: "We implement appropriate technical and organizational measures to protect your information from unauthorized access, use, or disclosure. However, no internet-based service can be completely secure, and we cannot guarantee the absolute security of your information."
    },
    {
      heading: "5. Your Rights",
      paragraph: "You have the right to access, update, or delete your personal information. You can do this by logging into your account or contacting us at info@cloudnext.dev. You also have the right to object to or restrict certain types of data processing and to lodge a complaint with your local data protection authority."
    },
    {
      heading: "6. Changes to This Policy",
      paragraph: "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our app. You are advised to review this Privacy Policy periodically for any changes."
    },
    {
      heading: "Contact Us",
      paragraph: "If you have any questions about this Privacy Policy, please contact us at info@cloudnext.dev."
    }
  ]
};

function PrivacyPolicy() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">{privacyContent.title}</h1>
      <p className="text-gray-600 mb-4">{privacyContent.intro}</p>
      {privacyContent.sections.map((section, index) => (
        <div key={index}>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">{section.heading}</h2>
          <p className="text-gray-600 mb-4">{section.paragraph}</p>
        </div>
      ))}
    </div>
  );
}

export default PrivacyPolicy;
