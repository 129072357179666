import { db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';

export const userService = {
  // Update user statistics
  updateUserStats: async (userId, entries) => {
    try {
      const glucoseEntries = entries.filter(entry => entry.type === 'glucose');
      const stats = {
        lastUpdated: new Date().toISOString(),
        totalEntries: entries.length,
        glucoseReadings: glucoseEntries.length,
      };

      if (glucoseEntries.length > 0) {
        const values = glucoseEntries.map(entry => parseFloat(entry.value));
        stats.averageGlucose = (values.reduce((a, b) => a + b, 0) / values.length).toFixed(1);
        stats.minGlucose = Math.min(...values).toFixed(1);
        stats.maxGlucose = Math.max(...values).toFixed(1);
      }

      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { stats }, { merge: true });
      return stats;
    } catch (error) {
      console.error('Error updating user stats:', error);
      throw error;
    }
  },

  // Get user profile and stats
  getUserProfile: async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        return userDoc.data();
      } else {
        // Initialize new user profile
        const newProfile = {
          createdAt: new Date().toISOString(),
          stats: {
            totalEntries: 0,
            glucoseReadings: 0
          }
        };
        await setDoc(userRef, newProfile);
        return newProfile;
      }
    } catch (error) {
      console.error('Error getting user profile:', error);
      throw error;
    }
  },

  // Update user preferences
  updateUserPreferences: async (userId, preferences) => {
    try {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { preferences }, { merge: true });
      return preferences;
    } catch (error) {
      console.error('Error updating user preferences:', error);
      throw error;
    }
  },

  // Create user profile
  createUserProfile: async (user, additionalData = {}) => {
    try {
      const userRef = doc(db, 'users', user.uid);
      const userData = {
        userId: user.uid,
        email: user.email,
        displayName: user.displayName || additionalData.name || null,
        signUpMethod: additionalData.signUpMethod || 'email',
        registrationDate: new Date().toISOString(),
        platformInfo: additionalData.platformInfo || {},
        stats: {
          totalEntries: 0,
          glucoseReadings: 0
        }
      };

      await setDoc(userRef, userData);
      return userData;
    } catch (error) {
      console.error('Error creating user profile:', error);
      throw error;
    }
  }
};
