import React from 'react';
import TableRow from './TableRow';

const EntriesTable = ({ 
  entries, 
  onEdit, 
  onDelete, 
  onUpdate, 
  onCancelEdit,
  editingId,
  formData,
  handleChange,
  isSaving 
}) => {
  return (
    <div className="table-container">
      <table className="w-full table-auto border-collapse mobile-table">
        <thead className="bg-gradient-to-r from-cyan-50 to-teal-50">
          <tr>
            <th className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date & Time</th>
            <th className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Activity</th>
            <th className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Value</th>
            <th className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Notes</th>
            <th className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {entries.map(entry => (
            <TableRow 
              key={`${entry.id}-${entry.type}`}
              entry={entry}
              isEditing={editingId === `${entry.id}-${entry.type}`}
              onEdit={onEdit}
              onDelete={onDelete}
              onUpdate={onUpdate}
              onCancelEdit={onCancelEdit}
              formData={formData}
              handleChange={handleChange}
              isSaving={isSaving}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EntriesTable;
