import React from 'react';
import { formatActivityValue, formatDateForDisplay } from '../utils/formatters';

const TableRow = ({ 
  entry, 
  isEditing, 
  onEdit, 
  onDelete, 
  onUpdate, 
  onCancelEdit,
  formData,
  handleChange,
  isSaving 
}) => {
  if (!entry || !entry.type) return null;

  const date = new Date(entry.datetime);
  const formattedTime = date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
  const datetime = `${formatDateForDisplay(date)} ${formattedTime}`;
  const typeName = entry.type.charAt(0).toUpperCase() + entry.type.slice(1);

  // Determine input type and step based on activity type
  const getInputProps = () => {
    switch(entry.type) {
      case 'sleep':
        return { type: 'number', step: '0.5' };
      case 'weight':
        return { type: 'number', step: '0.1' };
      case 'glucose':
        return { type: 'number', step: '0.1' };
      default:
        return { type: 'text' };
    }
  };

  const inputProps = getInputProps();

  return (
    <tr className="border-b hover:bg-gray-50">
      <td className="px-4 py-2 border border-gray-200 text-sm">{datetime}</td>
      <td className="px-4 py-2 border border-gray-200 text-sm">{typeName}</td>
      <td className="px-4 py-2 border border-gray-200 text-sm">
        {isEditing ? (
          <input
            type={inputProps.type}
            step={inputProps.step}
            value={formData[entry.type] || ''}
            onChange={(e) => handleChange(e)}
            name={entry.type}
            className="w-full p-1 border rounded"
          />
        ) : (
          formatActivityValue(entry.type, entry.value, entry.unit)
        )}
      </td>
      <td className="px-4 py-2 border border-gray-200 text-sm">
        {isEditing ? (
          <input
            type="text"
            value={formData.notes || ''}
            onChange={handleChange}
            name="notes"
            className="w-full p-1 border rounded"
          />
        ) : entry.notes}
      </td>
      <td className="px-4 py-2 border border-gray-200 text-sm">
        {isEditing ? (
          <div className="space-x-2">
            <button
              onClick={() => onUpdate(entry.id, entry.type)}
              className="text-green-600 hover:text-green-800 disabled:opacity-50"
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
            <button
              onClick={onCancelEdit}
              className="text-gray-600 hover:text-gray-800"
              disabled={isSaving}
            >
              Cancel
            </button>
          </div>
        ) : (
          <>
            <button
              onClick={() => onEdit(entry, entry.type)}
              className="text-cyan-700 hover:text-cyan-500 mr-2"
            >
              Edit
            </button>
            <button
              onClick={() => onDelete(entry.id, entry.type)}
              className="text-rose-500 hover:text-rose-300"
            >
              Delete
            </button>
          </>
        )}
      </td>
    </tr>
  );
};

export default TableRow;
