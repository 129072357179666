import React, { useState } from 'react';

const EntryFormModal = ({ isOpen, onClose, formData, handleChange, handleSubmit, saveSuccess, onFormInteraction }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [formValid, setFormValid] = useState(true);

  if (!isOpen) return null;

  const handleSubmitWrapper = async (e, keepOpen) => {
    setIsSaving(true);
    await handleSubmit(e, keepOpen);
    setIsSaving(false);
  };

  const handleInputInteraction = (e) => {
    // Reset validation on interaction
    setFormValid(true);
    onFormInteraction();
    handleChange(e);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-3xl shadow-lg p-8 w-full max-w-2xl max-h-[90vh] overflow-y-auto border border-gray-100">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-4">
            <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-cyan-700 to-cyan-950">
              Add a new entry
            </h2>
            {saveSuccess && (
              <span className="text-sm text-green-600 animate-fade-in mt-1">
                Entry saved
              </span>
            )}
          </div>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600 transition-colors">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <form onSubmit={(e) => handleSubmitWrapper(e)} className="space-y-6" onClick={onFormInteraction}>
          {/* Section 1: Date and Time */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-xs font-medium mb-1">Date (dd/mm/yyyy)</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputInteraction}
                className="w-full p-2 border rounded-xl"
                required
              />
            </div>
            
            <div>
              <label className="block text-xs font-medium mb-1">Time</label>
              <input
                type="time"
                name="time"
                value={formData.time}
                onChange={handleInputInteraction}
                className="w-full p-2 border rounded-xl"
                required
              />
            </div>
          </div>

          {/* Section 2: Glucose to Meal */}
          <div className="bg-gray-100 p-6 rounded-xl space-y-4">
            <p className="text-xs text-gray-500 mb-2">Fill in at least one of the following: Glucose, Medication, Meal, Exercise, Sleep, or Weight</p>
            
            {!formValid && (
              <p className="text-sm text-red-500 font-medium">
                Please fill in at least one entry field
              </p>
            )}
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex space-x-2">
                <div className="flex-1">
                  <label className="block text-xs font-medium mb-1">
                    Glucose ({formData.glucoseUnit === 'mmol' ? 'mmol/L' : 'mg/dL'})
                  </label>
                  <input
                    type="number"
                    step={formData.glucoseUnit === 'mmol' ? '0.1' : '1'}
                    name="glucose"
                    value={formData.glucose || ''}
                    onChange={handleInputInteraction}
                    className="w-full p-2 border rounded-xl"
                    placeholder="Enter value"
                  />
                </div>
                <div className="w-24">
                  <label className="block text-xs font-medium mb-1">Unit</label>
                  <select
                    name="glucoseUnit"
                    value={formData.glucoseUnit}
                    onChange={handleInputInteraction}
                    className="w-full p-2 border rounded-xl"
                  >
                    <option value="mmol">mmol/L</option>
                    <option value="mgdl">mg/dL</option>
                  </select>
                </div>
              </div>
              
              <div>
                <label className="block text-xs font-medium mb-1">Medication</label>
                <input
                  type="text"
                  name="medication"
                  value={formData.medication || ''}
                  onChange={handleInputInteraction}
                  className="w-full p-2 border rounded-xl"
                  placeholder="Enter medication"
                />
              </div>
              
              <div>
                <label className="block text-xs font-medium mb-1">Exercise</label>
                <input
                  type="text"
                  name="exercise"
                  value={formData.exercise || ''}
                  onChange={handleInputInteraction}
                  className="w-full p-2 border rounded-xl"
                  placeholder="Enter exercise details"
                />
              </div>
              
              <div>
                <label className="block text-xs font-medium mb-1">Sleep (hours)</label>
                <input
                  type="number"
                  step="0.5"
                  name="sleep"
                  value={formData.sleep || ''}
                  onChange={handleInputInteraction}
                  className="w-full p-2 border rounded-xl"
                  placeholder="Enter hours"
                />
              </div>
              
              <div>
                <label className="block text-xs font-medium mb-1">Meal</label>
                <input
                  type="text"
                  name="meal"
                  value={formData.meal || ''}
                  onChange={handleInputInteraction}
                  className="w-full p-2 border rounded-xl"
                  placeholder="Enter meal details"
                />
              </div>
              
              {/* Add the new Weight field */}
              <div>
                <label className="block text-xs font-medium mb-1">Weight (kg)</label>
                <input
                  type="number"
                  step="0.1"
                  name="weight"
                  value={formData.weight || ''}
                  onChange={handleInputInteraction}
                  className="w-full p-2 border rounded-xl"
                  placeholder="Enter weight"
                />
              </div>
            </div>
          </div>
          
          {/* Section 3: Notes */}
          <div>
            <label className="block text-xs font-medium mb-1">Notes</label>
            <textarea
              name="notes"
              value={formData.notes || ''}
              onChange={handleInputInteraction}
              className="w-full p-2 border rounded-xl"
              rows="2"
              placeholder="Add any additional notes here"
            ></textarea>
          </div>
          
          <div className="flex flex-col sm:flex-row gap-3">
            <button
              type="button"
              onClick={(e) => handleSubmitWrapper(e, true)}
              disabled={isSaving}
              className="w-full sm:w-auto px-8 py-3 text-sm font-semibold rounded-3xl bg-amber-200 text-gray-600 hover:bg-amber-100 transition-colors shadow-md hover:shadow-lg disabled:opacity-50"
            >
              {isSaving ? 'Saving...' : 'Save & add another'}
            </button>
            <button
              type="button"
              onClick={(e) => handleSubmitWrapper(e, false)}
              disabled={isSaving}
              className="w-full sm:w-auto px-8 py-3 text-sm font-semibold rounded-3xl border border-amber-200 text-gray-600 hover:bg-amber-50 transition-colors shadow-md hover:shadow-lg disabled:opacity-50"
            >
              {isSaving ? 'Saving...' : 'Save & close'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EntryFormModal;
