import React from 'react';
import { getFullDateRange } from './GlucoseStats';

function DateRangeSection({ dateRange, onDateRangeChange, onClear, entries }) {
  const fullRange = getFullDateRange(entries);
  const isShowingAllTime = dateRange.start === fullRange.start && 
                          dateRange.end === fullRange.end;

  return (
    <div className="bg-white rounded-3xl shadow-lg hover:shadow-xl transition-shadow p-6 border border-gray-100">
      <h3 className="font-semibold text-base text-gray-800 mb-3">Date Range</h3>
      <div className="flex flex-wrap items-center gap-4">
        <div className="flex items-center">
          <label className="font-medium text-gray-700 mr-2 text-sm">From</label>
          <input
            type="date"
            name="start"
            value={dateRange.start}
            onChange={onDateRangeChange}
            className="text-sm p-2 border rounded hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
          />
        </div>
        <div className="flex items-center">
          <label className="font-medium text-gray-700 mr-2 text-sm">To</label>
          <input
            type="date"
            name="end"
            value={dateRange.end}
            onChange={onDateRangeChange}
            className="text-sm p-2 border rounded hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
          />
        </div>
        <button
          onClick={onClear}
          disabled={isShowingAllTime}
          className={`text-sm font-medium ${
            isShowingAllTime 
              ? 'text-gray-400 cursor-not-allowed' 
              : 'text-cyan-700 hover:text-cyan-500'
          }`}
        >
          Reset to show all time data
        </button>
      </div>
    </div>
  );
}

export default DateRangeSection;
