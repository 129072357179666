import { useState } from 'react';
import { entryService } from '../services/entryService';

export const useEntries = (userId) => {
  const [entries, setEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadEntries = async () => {
    if (!userId) return;
    setIsLoading(true);
    try {
      const loadedEntries = await entryService.getEntries(userId);
      setEntries(loadedEntries);
      return loadedEntries;
    } catch (err) {
      setError(err.message);
      console.error('Error loading entries:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const addEntry = async (entryData) => {
    if (!userId) return;
    try {
      const newEntries = await entryService.createEntries(userId, [entryData]);
      setEntries(prev => [...newEntries, ...prev]);
      return newEntries[0];
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const updateEntry = async (entryId, updateData) => {
    if (!userId) return;
    try {
      await entryService.updateEntry(userId, entryId, updateData);
      setEntries(prev => prev.map(entry => 
        entry.id === entryId ? { ...entry, ...updateData } : entry
      ));
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const deleteEntry = async (entryId) => {
    if (!userId) return;
    try {
      await entryService.deleteEntry(userId, entryId);
      setEntries(prev => prev.filter(entry => entry.id !== entryId));
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  return {
    entries,
    isLoading,
    error,
    loadEntries,
    addEntry,
    updateEntry,
    deleteEntry,
    setEntries
  };
};
