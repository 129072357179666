import { useState } from 'react';

export const useEntryForm = (initialState, onSubmit) => {
  const [formData, setFormData] = useState(initialState);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async (e, keepOpen = false) => {
    e.preventDefault();
    setIsSaving(true);
    setSaveSuccess(false);
    
    try {
      await onSubmit(formData, keepOpen);
      setSaveSuccess(true);
    } catch (error) {
      console.error('Form submission error:', error);
      alert(error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const resetForm = (keepSomeFields = {}) => {
    setFormData(prev => ({
      ...initialState,
      ...keepSomeFields
    }));
    setSaveSuccess(false);
  };

  return {
    formData,
    setFormData,
    isSaving,
    saveSuccess,
    handleChange,
    handleSubmit,
    resetForm
  };
};
