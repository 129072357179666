import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCircleCheck, faTriangleExclamation, faPencil } from '@fortawesome/free-solid-svg-icons';
import { auth } from '../firebase';
import { updateProfile } from 'firebase/auth';
import { Link } from 'react-router-dom';

const UserModal = ({ isOpen, onClose, user, onFeedbackClick, onLogout }) => {
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState(user?.displayName || '');
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState('');

  if (!isOpen) return null;

  const handleNameUpdate = async () => {
    if (!newName.trim()) return;
    
    setIsUpdating(true);
    setUpdateError('');

    try {
      await updateProfile(auth.currentUser, {
        displayName: newName.trim()
      });
      setIsEditingName(false);
    } catch (error) {
      setUpdateError('Failed to update name. Please try again.');
      console.error('Error updating name:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleLogout = () => {
    setShowLogoutConfirm(false);
    onLogout();
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-2xl shadow-xl max-w-md w-full m-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold text-gray-800">Your Account</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="space-y-6">
          {/* Profile Section */}
          <div className="flex items-center space-x-4">
            <div className="bg-gradient-to-br from-teal-600 to-teal-300 w-12 h-12 rounded-full flex items-center justify-center">
              <span className="text-white text-base font-bold">
                {user.displayName ? user.displayName[0].toUpperCase() : user.email[0].toUpperCase()}
              </span>
            </div>
            <div className="flex-1">
              {isEditingName ? (
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    className="text-lg font-semibold text-gray-800 border-b border-gray-300 focus:border-teal-500 focus:outline-none pb-1"
                    placeholder="Enter your name"
                    autoFocus
                  />
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={handleNameUpdate}
                      disabled={isUpdating}
                      className="text-teal-600 hover:text-teal-700 text-sm font-medium"
                    >
                      {isUpdating ? 'Saving...' : 'Save'}
                    </button>
                    <button
                      onClick={() => {
                        setIsEditingName(false);
                        setNewName(user.displayName || '');
                        setUpdateError('');
                      }}
                      className="text-gray-500 hover:text-gray-600 text-sm font-medium"
                      disabled={isUpdating}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center space-x-2">
                  <h4 className="text-lg font-semibold text-gray-800">
                    {user.displayName || 'Add your name'}
                  </h4>
                  <button
                    onClick={() => setIsEditingName(true)}
                    className="text-gray-400 hover:text-gray-600 transition-colors"
                  >
                    <FontAwesomeIcon icon={faPencil} className="w-3 h-3" />
                  </button>
                </div>
              )}
              {updateError && (
                <p className="text-red-500 text-sm mt-1">{updateError}</p>
              )}
              <div className="flex items-center space-x-2 text-sm text-gray-500">
                <FontAwesomeIcon icon={faEnvelope} className="w-4 h-4" />
                <span>{user.email}</span>
              </div>
            </div>
          </div>

          {/* Verification Status */}
          <div className={`p-4 rounded-lg ${user.emailVerified ? 'bg-green-50' : 'bg-yellow-50'}`}>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon 
                icon={user.emailVerified ? faCircleCheck : faTriangleExclamation} 
                className={`w-5 h-5 ${user.emailVerified ? 'text-green-500' : 'text-yellow-500'}`}
              />
              <span className={`text-sm ${user.emailVerified ? 'text-green-700' : 'text-yellow-700'}`}>
                {user.emailVerified ? 'Email verified' : 'Email not verified'}
              </span>
            </div>
          </div>

          {/* Actions */}
          <div className="pt-4">
            <button
              onClick={() => setShowLogoutConfirm(true)}
              className="w-full py-3 px-4 text-red-600 hover:text-red-700 hover:bg-red-50 rounded-lg transition-colors flex items-center justify-between"
            >
              <span className="text-sm font-medium">Logout</span>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Logout confirmation dialog */}
      {showLogoutConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-2xl shadow-xl max-w-sm w-full mx-4">
            <h3 className="text-xl font-bold text-gray-800 mb-4">Confirm Logout</h3>
            <p className="text-gray-600 mb-6">Are you sure you want to logout?</p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowLogoutConfirm(false)}
                className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleLogout}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-lg transition-colors"
              >

                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserModal;
