import { useEffect, useState } from 'react';
import { applyActionCode, auth } from '../firebase';
import { useSearchParams, useNavigate } from 'react-router-dom';

function EmailVerification() {
  const [status, setStatus] = useState('verifying');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const actionCode = searchParams.get('oobCode');

  useEffect(() => {
    const verifyEmail = async () => {
      if (!actionCode) {
        setStatus('error');
        return;
      }

      try {
        await applyActionCode(auth, actionCode);
        
        // Force refresh the user and wait for it
        if (auth.currentUser) {
          await auth.currentUser.reload();
          setStatus('success');
          
          // Redirect after 3 seconds
          setTimeout(() => {
            navigate('/');
          }, 3000);
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setStatus('error');
      }
    };

    verifyEmail();
  }, [actionCode, navigate]);

  if (status === 'verifying') {
    return (
      <div className="text-center p-8">
        <p>Verifying your email...</p>
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className="text-center p-8">
        <h2 className="text-red-600">Failed to verify email</h2>
        <p>The verification link may be invalid or expired.</p>
      </div>
    );
  }

  return (
    <div className="text-center p-8">
      <h2 className="text-green-600">Email verified successfully!</h2>
      <p>You can now close this window and return to the app.</p>
    </div>
  );
}

export default EmailVerification;
