// App.js
import { useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { doc, setDoc } from 'firebase/firestore';
import { ArrowRightIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { onAuthStateChanged } from 'firebase/auth';
import Auth from './components/Auth';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import EmailVerification from './components/EmailVerification';
import { entryService } from './services/entryService';
import { userService } from './services/userService';
import EntryFormModal from './components/EntryFormModal';
import LandingPage from './components/LandingPage';
import GlucoseStats from './components/GlucoseStats';
import DateRangeSection from './components/DateRangeSection';
import GlucoseChart from './components/GlucoseChart';
import TermsOfUse from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';
import UserModal from './components/UserModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faComment, faDownload } from '@fortawesome/free-solid-svg-icons';
import { getFullDateRange } from './components/GlucoseStats';
import { useEntries } from './hooks/useEntries';
import { useEntryForm } from './hooks/useEntryForm';
import EntriesTable from './components/EntriesTable';
// Import the new utility function
import { processFormToEntries } from './utils/formProcessing';
import { formatActivityValue, formatDateForDisplay, convertToMgDl, convertToMmol } from './utils/formatters';
import WeightChart from './components/WeightChart';
import WeightStats from './components/WeightStats';

// Add this style block after imports
const pulseStyles = `
  @keyframes pulseText {
    0%, 100% { font-size: .98rem; }
    80% { font-size: 1rem; }
  }
  .animate-pulse-text {
    animation: pulseText 2s infinite ease-in-out;
  }
`;

// Update the responsiveTableStyles const
const responsiveTableStyles = `
  .table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    max-width: none;  /* Remove any max-width constraint */
  }
  .mobile-table {
    width: max-content;  /* Allow table to grow based on content */
    min-width: 100%;    /* Ensure it fills container */
  }
  .mobile-table .date-column {
    min-width: 140px;
    white-space: nowrap;
  }
  .mobile-table .type-column {
    min-width: 100px;
    white-space: nowrap;
  }
  .mobile-table .value-column {
    min-width: 180px;
    max-width: 350px;     
    white-space: normal;
    word-wrap: break-word;
  }
  .mobile-table .notes-column {
    min-width: 200px;
    max-width: 400px;  
    white-space: normal;
    word-wrap: break-word;
  }
  .mobile-table .actions-column {
    min-width: 120px;
    white-space: nowrap;
  }
  @media (max-width: 640px) {
    .mobile-table {
      font-size: 0.75rem;
    }
    .mobile-table td,
    .mobile-table th {
      padding: 0.5rem 0.75rem;
    }
  }
`;

// Add this helper function after other helper functions
const exportToCSV = (entries) => {
  // Headers for the CSV file
  const headers = ['Date', 'Time', 'Activity', 'Value', 'Notes'];
  
  // Transform entries into CSV rows
  const rows = entries.map(entry => {
    const date = new Date(entry.datetime);
    return [
      date.toLocaleDateString('en-GB'),
      date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
      entry.type,
      entry.value,
      entry.notes || ''
    ];
  });

  // Combine headers and rows
  const csvContent = [headers, ...rows].map(e => e.join(',')).join('\n');

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Create a link element and trigger the download
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'glucose_tracker_data.csv');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function App() {
  // Add new state for welcome message
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  // Remove this duplicate declaration
  // const [saveSuccess, setSaveSuccess] = useState(false);
  // Add this new state near the other state declarations
  const [isSaving, setIsSaving] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  // Add new state for initial loading
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [weightUnit, setWeightUnit] = useState('kg');

  const getCurrentDateTime = () => {
    const now = new Date();
    return {
      date: now.toISOString().split('T')[0], // keep ISO format for input
      displayDate: formatDateForDisplay(now), // for display
      time: now.toTimeString().slice(0,5)
    };
  };

  const [user, setUser] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [deletingItem, setDeletingItem] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: '',
    end: ''
  });
  const [activeFilter, setActiveFilter] = useState('all');
  const [viewMode, setViewMode] = useState('table'); // 'table' or 'chart'
  // removed showAllTime state

  // Replace entries state and handlers with useEntries hook
  const {
    entries,
    isLoading: isEntriesLoading,
    error: entriesError,
    loadEntries,
    addEntry,
    updateEntry,
    deleteEntry,
    setEntries
  } = useEntries(user?.uid);

  // Replace form state and handlers with useEntryForm hook
  const {
    formData,
    setFormData,
    isSaving: isFormSaving,
    saveSuccess,  // Use this saveSuccess from the hook
    handleChange,  // We'll use this handleChange from the hook
    handleSubmit: handleFormSubmit,
    resetForm
  } = useEntryForm({
    date: getCurrentDateTime().date,
    time: getCurrentDateTime().time,
    glucose: '',
    glucoseUnit: 'mmol',
    medication: '',
    exercise: '',
    sleep: '',
    meal: '',
    weight: '',  // Added weight field
    notes: ''
  }, async (data, keepOpen) => {
    // Form submission logic moved here
    if (!user?.uid) return;

    const hasTrackingData = data.glucose || 
                           data.medication || 
                           data.meal || 
                           data.exercise || 
                           data.sleep ||
                           data.weight;  // Added weight check
    
    if (!hasTrackingData) {
      throw new Error('Please fill in at least one tracking field');
    }

    // Process form data into multiple entries
    const entriesToAdd = processFormToEntries(data);
    
    if (entriesToAdd.length === 0) {
      throw new Error('No valid data to save');
    }

    // Create all entries at once
    const newEntries = await entryService.createEntries(user.uid, entriesToAdd);

    // Update entries state by adding new entries and sorting
    setEntries(prevEntries => {
      const combinedEntries = [...prevEntries, ...newEntries];
      return combinedEntries.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
    });

    if (!keepOpen) {
      setShowForm(false);
    }

    resetForm({
      date: data.date,
      time: data.time,
      glucoseUnit: data.glucoseUnit
    });
  });

  // Load entries from Firestore when component mounts or user changes
  useEffect(() => {
    const loadEntries = async () => {
      if (!user?.uid) return;

      try {
        const loadedEntries = await entryService.getEntries(user.uid);
        setEntries(loadedEntries);
        // Set initial date range after entries are loaded
        setDateRange(getFullDateRange(loadedEntries));
      } catch (error) {
        console.error('Error loading entries:', error);
      }
    };

    loadEntries();
  }, [user]);

  const handleSubmit = async (e, keepOpen = false) => {
    e.preventDefault();
    
    if (!user?.uid) return;

    // Use handleFormSubmit from the hook instead of direct setSaveSuccess
    await handleFormSubmit(e, keepOpen);

    try {
      // ...existing submission logic...
      
      // Remove this line as success handling is now in the hook
      // setSaveSuccess(true);

      if (!keepOpen) {
        setShowForm(false);
      }
    } catch (error) {
      console.error('Error saving entries:', error);
      alert(`Failed to save entries: ${error.message}`);
    }
  };

  // Add handler to clear success message
  const handleFormInteraction = () => {
    resetForm(formData);  // This will reset success state while preserving form data
  };

  const handleDateRangeChange = (e) => {
    setDateRange(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDeleteClick = (entryId, activityType) => {
    setDeletingItem({ id: entryId, type: activityType });
  };

  const handleDeleteConfirm = async () => {
    if (!deletingItem || !user?.uid) return;
    
    try {
      await entryService.deleteEntry(user.uid, deletingItem.id);
      
      // Update entries state by filtering out only the deleted entry
      setEntries(currentEntries => 
        currentEntries.filter(entry => entry.id !== deletingItem.id)
      );
      
      setDeletingItem(null);
    } catch (error) {
      console.error('Error deleting entry:', error);
      alert('Failed to delete entry. Please try again.');
    }
  };

  const handleDeleteCancel = () => {
    setDeletingItem(null);
  };

  const handleEdit = (entry, activityType) => {
    if (!entry || !activityType) return;
    
    setEditingId(`${entry.id}-${entry.type}`);
    setFormData({
      ...formData,
      [entry.type]: entry.value,  // Use entry.type directly to match the data structure
      date: new Date(entry.datetime).toISOString().split('T')[0],
      time: new Date(entry.datetime).toTimeString().slice(0,5),
      notes: entry.notes || ''
    });
  };

  const handleUpdate = async (entryId, activityType) => {
    if (!user?.uid) return;

    setIsSaving(true); // Set saving state to true when update starts
    try {
      const datetime = `${formData.date}T${formData.time}`;
      const updateData = {
        type: activityType,
        value: formData[activityType], // Use activityType directly
        datetime,
        notes: formData.notes
      };

      // Add unit for glucose entries
      if (activityType === 'glucose') {
        updateData.unit = 'mmol';
      }

      await entryService.updateEntry(user.uid, entryId, updateData);
      
      // Update the local entries state
      setEntries(entries.map(entry => {
        if (entry.id === entryId) {
          return { ...entry, ...updateData };
        }
        return entry;
      }));

      setEditingId(null);
      setFormData({
        ...formData,
        [activityType]: '',
        notes: ''
      });
    } catch (error) {
      console.error('Error updating entry:', error);
      alert('Failed to update entry. Please try again.');
    } finally {
      setIsSaving(false); // Reset saving state whether update succeeds or fails
    }
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setFormData({
      ...formData,
      glucose: '',
      medication: '',
      exercise: '',
      sleep: '',
      meal: '',
      weight: '', // Add weight field reset
      notes: ''
    });
  };

  const getAvailableActivities = () => {
    if (!entries || entries.length === 0) return ['all'];
    
    const activities = new Set(entries
      .filter(entry => entry && entry.type) // Add null check
      .map(entry => entry.type.charAt(0).toUpperCase() + entry.type.slice(1))
    );
    return ['all', ...Array.from(activities)];
  };

  const handleFormClick = (e) => {
    if (!user?.emailVerified) {
      e.preventDefault();
      alert('Please verify your email address before adding entries. Check your inbox for the verification link.');
      return false;
    }
    return true;
  };

  // Add click handler for the entire app container
  const handleAppClick = () => {
    setShowWelcomeMessage(false);
  };

  const handleFeedbackClick = (e) => {
    e.preventDefault();
    window.location.href = 'mailto:info@cloudnext.dev?subject=Glucose Tracker Web App Feedback';
  };

  useEffect(() => {
    console.log('Setting up auth listeners...');
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          console.log('Auth state changed, current user:', user.email);
          
          // Store user info in localStorage
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('userEmail', user.email);
          
          // Store the current verification status
          const wasVerified = user.emailVerified;
          
          // Force reload and get fresh user object
          await user.reload();
          const freshUser = auth.currentUser;
          
          if (freshUser) {
            console.log('Fresh verification status:', freshUser.emailVerified);
            
            // Update Firestore when email is verified
            if (freshUser.emailVerified) {
              try {
                await setDoc(doc(db, 'users', freshUser.uid), {
                  emailVerified: true,
                  emailVerifiedAt: new Date().toISOString()
                }, { merge: true });
                console.log('Updated emailVerified status in Firestore');
              } catch (error) {
                console.error('Error updating Firestore:', error);
              }
            }
            
            // Only show welcome message when transitioning from unverified to verified
            if (!wasVerified && freshUser.emailVerified) {
              console.log('Email newly verified, showing welcome message');
              setShowWelcomeMessage(true);
            }
            
            setUser(freshUser);
          } else {
            console.log('User no longer exists after reload');
            setUser(null);
            setShowWelcomeMessage(false);
            // Clear localStorage
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('userEmail');
          }
        } catch (error) {
          console.error('Error reloading user:', error);
          setUser(null);
          setShowWelcomeMessage(false);
          // Clear localStorage
          localStorage.removeItem('isLoggedIn');
          localStorage.removeItem('userEmail');
        }
      } else {
        console.log('No user signed in');
        setUser(null);
        setShowWelcomeMessage(false);
        // Clear localStorage
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('userEmail');
      }
      // Set initial loading to false after auth state is determined
      setIsInitialLoading(false);
    });

    // Modified interval check with error handling
    const intervalId = setInterval(async () => {
      if (auth.currentUser && !auth.currentUser.emailVerified) {
        console.log('Checking verification status...', new Date().toLocaleTimeString());
        try {
          await auth.currentUser.reload();
          const freshUser = auth.currentUser;
          
          if (freshUser && freshUser.emailVerified) {
            console.log('Email newly verified, showing welcome message');
            setShowWelcomeMessage(true);
            setUser({ ...freshUser });
            clearInterval(intervalId);
          }
        } catch (error) {
          // Only log actual errors, not connection interruptions
          if (!error.message.includes('ERR_BLOCKED_BY_CLIENT')) {
            console.error('Error checking verification status:', error);
          }
        }
      }
    }, 5000);

    return () => {
      console.log('Cleaning up auth listeners');
      unsubscribe();
      clearInterval(intervalId);
    };
  }, []);

  const renderEntries = () => {
    if (!entries || !Array.isArray(entries)) return null;

    const isMobile = window.innerWidth <= 640;
  
    return entries
      .filter(entry => entry && entry.type && entry.datetime) // Ensure entry has required properties
      .filter(entry => {
        // Add date range filtering
        if (!dateRange.start && !dateRange.end) return true;
        
        const entryDate = new Date(entry.datetime);
        const startDate = dateRange.start ? new Date(dateRange.start) : null;
        const endDate = dateRange.end ? new Date(dateRange.end) : null;
        
        if (startDate) startDate.setHours(0, 0, 0, 0);
        if (endDate) endDate.setHours(23, 59, 59, 999);
        
        if (startDate && entryDate < startDate) return false;
        if (endDate && entryDate > endDate) return false;
        return true;
      })
      .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
      .filter(entry => 
        activeFilter === 'all' || 
        (entry.type && entry.type.toLowerCase() === activeFilter.toLowerCase())
      )
      .map(entry => {
        if (!entry || !entry.type) return null;

        const date = new Date(entry.datetime);
        const formattedDate = formatDateForDisplay(date);  // This will now show as 20/Dec/2024
        const formattedTime = date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
          ...(isMobile && { hourCycle: 'h12' }) // Forces 12-hour format on mobile
        });
        const datetime = `${formattedDate} ${formattedTime}`;
        const rowId = `${entry.id}-${entry.type}`;
        const isEditing = editingId === rowId;
        const typeName = entry.type.charAt(0).toUpperCase() + entry.type.slice(1);

        return (
          <tr key={rowId} className="border-b hover:bg-gray-50">
            <td className="px-4 py-2 border border-gray-200 text-sm date-column">
              {datetime}
            </td>
            <td className="px-4 py-2 border border-gray-200 text-sm type-column">
              {typeName}
            </td>
            <td className="px-4 py-2 border border-gray-200 text-sm value-column">
              {isEditing ? (
                <input
                  type={entry.type === 'sleep' ? 'number' : 'text'}
                  step={entry.type === 'sleep' ? '0.5' : undefined}
                  value={formData[entry.type] || ''}
                  onChange={(e) => setFormData({
                    ...formData,
                    [entry.type]: e.target.value
                  })}
                  className="w-full p-1 border rounded"
                />
              ) : (
                formatActivityValue(entry.type, entry.value, entry.unit)
              )}
            </td>
            <td className="px-4 py-2 border border-gray-200 text-sm notes-column">
              {isEditing ? (
                <input
                  type="text"
                  value={formData.notes}
                  onChange={handleChange}
                  name="notes"
                  className="w-full p-1 border rounded"
                />
              ) : entry.notes}
            </td>
            <td className="px-4 py-2 border border-gray-200 text-sm actions-column">
              {isEditing ? (
                <div className="space-x-2">
                  <button
                    onClick={() => handleUpdate(entry.id, entry.type)}
                    className="text-green-600 hover:text-green-800 disabled:opacity-50"
                    disabled={isSaving}
                  >
                    {isSaving ? 'Saving...' : 'Save'}
                  </button>
                  <button
                    onClick={handleCancelEdit}
                    className="text-gray-600 hover:text-gray-800"
                    disabled={isSaving}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <>
                  <button
                    onClick={() => handleEdit(entry, entry.type)}
                    className="text-cyan-700 hover:text-cyan-500 mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteClick(entry.id, entry.type)}
                    className="text-rose-500 hover:text-rose-300"
                  >
                    Delete
                  </button>
                </>
              )}
            </td>
          </tr>
        );
      })
      .filter(Boolean); // Remove any null entries
  };

  // Update the handler for resetting date range
  const handleDateRangeClear = () => {
    setDateRange(getFullDateRange(entries));
  };

  return (
    <BrowserRouter>
      <div className="min-h-screen bg-gradient-to-br from-teal-50 via-white to-teal-50" onClick={handleAppClick}>
        <style>{pulseStyles}</style>
        <style>{responsiveTableStyles}</style>
        <Routes>
          <Route 
            path="/__/auth/action" 
            element={<EmailVerification />} 
          />
          <Route 
            path="/" 
            element={
              <div>
                {isInitialLoading ? (
                  <div className="flex flex-col items-center justify-center min-h-screen">
                    <div className="text-2xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-gray-800 mb-8">
                      Peak Health
                    </div>
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mb-4"></div>
                    <div className="text-gray-600">
                      Your data is loading
                    </div>
                  </div>
                ) : (
                  !user && !localStorage.getItem('isLoggedIn') ? (
                    <LandingPage />
                  ) : (
                    <div className="container mx-auto px-4 py-8 max-w-7xl">
                      <div className="flex justify-between items-center mb-6">
                        <div className="flex items-center space-x-2 ml-4">
                          <img 
                            src="/logo100.png"
                            alt="Peak Health Logo"
                            className="h-10 w-10 object-contain"
                          />
                          <div className="text-lg font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-gray-800">
                            Peak Health
                          </div>
                        </div>
                        <div className="flex items-center space-x-4 mr-4"> {/* Changed space-x-2 to space-x-4 */}
                          <button
                            onClick={() => {
                              window.location.href = 'mailto:info@cloudnext.dev?subject=Peak Health Feedback';
                            }}
                            className="w-9 h-9 flex items-center justify-center text-gray-600 hover:text-gray-800 bg-lime-300 hover:bg-amber-50 rounded-full transition-colors relative group"
                          >
                            <FontAwesomeIcon icon={faComment} className="w-5 h-5" />
                            <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-2 py-1 rounded text-xs whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity">
                              Send feedback
                            </div>
                          </button>
                          <button
                            onClick={() => setShowUserModal(true)}
                            className="w-9 h-9 flex items-center justify-center text-gray-600 hover:text-gray-800 bg-lime-300 hover:bg-amber-50 rounded-full transition-colors relative group"
                          >
                            <FontAwesomeIcon icon={faUser} className="w-5 h-5" />
                            <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-2 py-1 rounded text-xs whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity">
                              Your account
                            </div>
                          </button>
                        </div>
                      </div>

                      <UserModal
                        isOpen={showUserModal}
                        onClose={() => setShowUserModal(false)}
                        user={user}
                        onFeedbackClick={handleFeedbackClick}
                        onLogout={() => auth.signOut()}
                      />

                      {/* Rest of your existing content */}
                      {!user ? (
                        <Auth />
                      ) : (
                        <>
                          
                          {/* Add welcome message */}
                          {showWelcomeMessage && (
                            <div className="mb-6 p-4 bg-green-50 border border-green-200 rounded-3xl">
                              <p className="text-green-800">
                                Thank you for verifying your email! You can now use the app.
                              </p>
                            </div>
                          )}

                          {!user.emailVerified && (
                            <div className="mb-6 p-4 bg-amber-50 border border-amber-200 rounded-3xl">
                              <p className="text-yellow-800">
                                Please verify your email address to start using the app. 
                                Check your inbox for the verification link.
                              </p>
                            </div>
                          )}

                          <div className="container mx-auto px-4 py-8">
                            <h1 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-teal-700 mb-2">
                              Your Health Report
                            </h1>
                            <p className="text-gray-600 mb-6">Track food intake, activities, weight, and blood sugar to get insights into your health.</p>

                            {/* Statistics section - now with three components */}
                            <div className="grid md:grid-cols-3 gap-6 mb-8">
                              <GlucoseStats 
                                dateRange={dateRange}
                                entries={entries}
                              />
                              <WeightStats
                                dateRange={dateRange}
                                entries={entries}
                              />
                              <DateRangeSection 
                                dateRange={dateRange}
                                onDateRangeChange={handleDateRangeChange}
                                onClear={handleDateRangeClear}
                                entries={entries}  
                              />
                            </div>

                            {/* Form toggle button */}
                            <button
                              onClick={() => user.emailVerified && setShowForm(!showForm)}
                              className={`mb-6 inline-flex items-center px-8 py-3 text-sm font-semibold ${
                                user.emailVerified 
                                  ? 'bg-amber-200 text-gray-600 hover:bg-amber-100' 
                                  : 'bg-gray-100 text-gray-500 cursor-not-allowed'
                              } rounded-3xl transition-colors shadow-md hover:shadow-lg`}
                            >
                              {showForm ? (
                                <>
                                  Hide form
                                  <ChevronUpIcon className="h-4 w-4 ml-2" />
                                </>
                              ) : (
                                <>
                                  {entries.length === 0 ? 'Add your first glucose reading or activity' 
                                  : 'Log a new entry'}
                                  <ArrowRightIcon className="h-4 w-4 ml-2" />
                                </>
                              )}
                            </button>

                            {/* Replace the old form with the new modal */}
                            <EntryFormModal 
                              isOpen={showForm}
                              onClose={() => setShowForm(false)}
                              formData={formData}
                              handleChange={handleChange}
                              handleSubmit={handleSubmit}
                              saveSuccess={saveSuccess}
                              onFormInteraction={handleFormInteraction}
                            />

                            {/* Activity filter and view toggle */}
                            <div className="mb-4">
                              <h2 className="text-xl font-semibold text-gray-800 mt-1 mb-3">
                                {viewMode === 'table' ? 'Your health data' : 'Your health charts'}
                              </h2>
                              <div className="flex justify-between items-center">
                                {viewMode === 'table' ? (
                                  <div className="flex items-center">
                                    <label className="font-medium text-gray-700 mr-2 text-sm md:text-sm text-xs">Filter by activity:</label>
                                    <select
                                      value={activeFilter}
                                      onChange={(e) => setActiveFilter(e.target.value)}
                                      className="text-xs md:text-sm p-2 border rounded hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
                                    >
                                      {getAvailableActivities().map(activity => (
                                        <option key={activity} value={activity.toLowerCase()} className="text-xs md:text-sm">
                                          {activity === 'all' ? 'All activities' : activity}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                ) : (
                                  <div className="text-xs md:text-sm font-medium text-gray-700">
                                    {/* Your glucose levels over time */}
                                  </div>
                                )}
                                
                                <div className="flex gap-2 ml-2" >
                                  <button
                                    onClick={() => setViewMode('table')}
                                    className={`px-3 md:px-4 py-1.5 md:py-2 rounded-lg text-xs md:text-sm font-medium transition-colors ${
                                      viewMode === 'table'
                                        ? 'bg-amber-100 text-gray-700'
                                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                                    }`}
                                  >
                                    Table View
                                  </button>
                                  <button
                                    onClick={() => setViewMode('chart')}
                                    className={`px-3 md:px-4 py-1.5 md:py-2 rounded-lg text-xs md:text-sm font-medium transition-colors ${
                                      viewMode === 'chart'
                                        ? 'bg-amber-100 text-gray-700'
                                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                                    }`}
                                  >
                                    Chart View
                                  </button>
                                  <button
                                    onClick={() => exportToCSV(entries)}
                                    className="px-3 md:px-4 py-1.5 md:py-2 rounded-lg text-xs md:text-sm font-medium bg-lime-300 text-gray-700 hover:bg-lime-200 transition-colors flex items-center"
                                  >
                                    <FontAwesomeIcon icon={faDownload} className="mr-2" />
                                    Export CSV
                                  </button>
                                </div>
                              </div>
                            </div>

                            {/* Entries table or chart */}
                            <div className="bg-white rounded-3xl shadow-lg hover:shadow-xl transition-shadow overflow-hidden border border-gray-100">
                              {viewMode === 'chart' ? (
                                <div className="space-y-4">
                                  <h2 className="text-lg font-semibold text-gray-800 mt-4 px-4">Your Glucose Levels Over Time</h2>
                                  <GlucoseChart entries={entries} dateRange={dateRange} />
                                  <h2 className="text-lg font-semibold text-gray-800 mt-8 px-4">Your Weight Over Time</h2>
                                  <WeightChart entries={entries} dateRange={dateRange} />
                                </div>
                              ) : (
                                <EntriesTable
                                  entries={entries.filter(entry => 
                                    activeFilter === 'all' || 
                                    (entry.type && entry.type.toLowerCase() === activeFilter.toLowerCase())
                                  )}
                                  onEdit={handleEdit}
                                  onDelete={handleDeleteClick}
                                  onUpdate={handleUpdate}
                                  onCancelEdit={handleCancelEdit}
                                  editingId={editingId}
                                  formData={formData}
                                  handleChange={handleChange}
                                  isSaving={isSaving}
                                />
                              )}
                            </div>
                            {/* Delete confirmation modal */}
                            {deletingItem && (
                              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                                <div className="bg-white p-6 rounded-2xl shadow-xl max-w-sm w-full mx-4">
                                  <h3 className="text-xl font-bold text-gray-800 mb-4">Confirm Delete</h3>
                                  <p className="text-gray-600 mb-6">
                                    Are you sure you want to delete this {deletingItem.type.toLowerCase()} entry?
                                  </p>
                                  <div className="flex justify-end space-x-3">
                                    <button
                                      onClick={handleDeleteCancel}
                                      className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      onClick={handleDeleteConfirm}
                                      className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-lg transition-colors"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )
                )}
              </div>
            } 
          />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 py-2">
          <div className="container mx-auto px-4 text-center text-xs text-gray-500">
            <Link to="/terms-of-use" target="_blank" className="hover:text-teal-600 transition-colors">
              Terms of Use
            </Link>
            <span className="mx-2">·</span>
            <Link to="/privacy-policy" target="_blank" className="hover:text-teal-600 transition-colors">
              Privacy Policy
            </Link>
            <span className="mx-2">·</span>
            <a 
              href="https://cloudnext.dev" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="hover:text-teal-600 transition-colors"
            >
              Powered by CloudNext
            </a>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}
export default App;