import { db } from '../firebase';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc, query, orderBy } from 'firebase/firestore';
import { userService } from './userService';

export const entryService = {
  async createEntries(userId, entries) {
    try {
      console.log('Creating entries:', entries); // Debug log
      
      const entriesCollection = collection(db, 'users', userId, 'entries');
      const createdEntries = [];

      // Make sure entries is always an array
      const entriesToCreate = Array.isArray(entries) ? entries : [entries];

      // Create each entry in the database
      for (const entry of entriesToCreate) {
        if (!entry.type || !entry.datetime) {
          console.error('Invalid entry data:', entry);
          continue; // Skip invalid entries
        }

        try {
          const docRef = await addDoc(entriesCollection, {
            type: entry.type,
            value: entry.value,
            unit: entry.type === 'glucose' ? entry.unit : null, // Only include unit for glucose
            datetime: entry.datetime,
            notes: entry.notes || '',
            createdAt: new Date().toISOString()
          });

          createdEntries.push({
            id: docRef.id,
            ...entry
          });
        } catch (error) {
          console.error(`Error creating ${entry.type} entry:`, error);
        }
      }

      console.log('Created entries:', createdEntries); // Debug log

      // After successfully creating entries, update user stats
      const allEntries = await this.getEntries(userId);
      await userService.updateUserStats(userId, allEntries);

      return createdEntries;
    } catch (error) {
      console.error('Error in createEntries:', error);
      throw error;
    }
  },

  // Get all entries for a user
  getEntries: async (userId) => {
    try {
      const entriesRef = collection(db, 'users', userId, 'entries');
      const q = query(entriesRef, orderBy('datetime', 'desc'));
      const snapshot = await getDocs(q);
      
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error getting entries:', error);
      throw error;
    }
  },

  // Update an existing entry
  updateEntry: async (userId, entryId, updateData) => {
    try {
      const entryRef = doc(db, 'users', userId, 'entries', entryId);
      await updateDoc(entryRef, {
        ...updateData,
        updatedAt: new Date().toISOString()
      });

      // After successfully updating entry, update user stats
      const allEntries = await entryService.getEntries(userId);
      await userService.updateUserStats(userId, allEntries);
    } catch (error) {
      console.error('Error updating entry:', error);
      throw error;
    }
  },

  // Delete an entry
  deleteEntry: async (userId, entryId) => {
    try {
      const entryRef = doc(db, 'users', userId, 'entries', entryId);
      await deleteDoc(entryRef);
      
      // After successfully deleting entry, update user stats
      const allEntries = await entryService.getEntries(userId);
      await userService.updateUserStats(userId, allEntries);
    } catch (error) {
      console.error('Error deleting entry:', error);
      throw error;
    }
  }
};
