/**
 * Processes form data from the entry form and converts it to an array of entry objects
 * ready to be saved to the database
 * 
 * @param {Object} formData - The raw form data from the entry form
 * @returns {Array} - Array of entry objects ready for database insertion
 */
export const processFormToEntries = (formData) => {
  const { date, time, notes, glucoseUnit, ...entryValues } = formData;
  const datetime = `${date}T${time}`;
  const entries = [];
  
  // Process glucose if present
  if (entryValues.glucose && entryValues.glucose !== '') {
    entries.push({
      type: 'glucose',
      value: entryValues.glucose,
      unit: glucoseUnit || 'mmol',
      datetime,
      notes
    });
  }
  
  // Process medication if present
  if (entryValues.medication && entryValues.medication !== '') {
    entries.push({
      type: 'medication',
      value: entryValues.medication,
      datetime,
      notes
    });
  }
  
  // Process meal if present
  if (entryValues.meal && entryValues.meal !== '') {
    entries.push({
      type: 'meal',
      value: entryValues.meal,
      datetime,
      notes
    });
  }
  
  // Process exercise if present
  if (entryValues.exercise && entryValues.exercise !== '') {
    entries.push({
      type: 'exercise',
      value: entryValues.exercise,
      datetime,
      notes
    });
  }
  
  // Process sleep if present
  if (entryValues.sleep && entryValues.sleep !== '') {
    entries.push({
      type: 'sleep',
      value: entryValues.sleep,
      datetime,
      notes
    });
  }
  
  // Process weight if present
  if (entryValues.weight && entryValues.weight !== '') {
    entries.push({
      type: 'weight',
      value: entryValues.weight,
      datetime,
      notes
    });
  }
  
  return entries;
};
