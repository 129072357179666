import React from 'react';

const WeightStats = ({ dateRange, entries }) => {
  // Filter weight entries based on date range
  const weightEntries = entries
    .filter(entry => entry.type === 'weight')
    .filter(entry => {
      if (!dateRange.start && !dateRange.end) return true;
      
      const entryDate = new Date(entry.datetime);
      const startDate = dateRange.start ? new Date(dateRange.start) : null;
      const endDate = dateRange.end ? new Date(dateRange.end) : null;
      
      if (startDate) startDate.setHours(0, 0, 0, 0);
      if (endDate) endDate.setHours(23, 59, 59, 999);
      
      if (startDate && entryDate < startDate) return false;
      if (endDate && entryDate > endDate) return false;
      return true;
    })
    .sort((a, b) => new Date(a.datetime) - new Date(b.datetime));

  if (weightEntries.length < 2) {
    return (
      <div className="bg-white rounded-3xl shadow-lg hover:shadow-xl transition-shadow p-6 border border-pink-100">
        <h3 className="font-semibold text-lg text-gray-900 mb-1">Weight Change</h3>
        <div className="text-gray-600">
          Not enough weight data in selected date range (need at least 2 entries)
        </div>
      </div>
    );
  }

  // Get first and last weight entries in the range
  const firstEntry = weightEntries[0];
  const lastEntry = weightEntries[weightEntries.length - 1];
  
  const startWeight = parseFloat(firstEntry.value);
  const endWeight = parseFloat(lastEntry.value);
  
  const weightChange = (endWeight - startWeight).toFixed(1);
  const percentChange = ((endWeight - startWeight) / startWeight * 100).toFixed(1);
  
  // Determine if weight increased, decreased, or stayed the same
  let changeDirection = "maintained";
  let changeColor = "text-gray-700";
  let trendIcon = "→";
  
  if (weightChange > 0) {
    changeDirection = "gained";
    changeColor = "text-rose-600";
    trendIcon = "↑";
  } else if (weightChange < 0) {
    changeDirection = "lost";
    changeColor = "text-emerald-600";
    trendIcon = "↓";
  }
  
  // Format dates for display
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };

  return (
    <div className="bg-white rounded-3xl shadow-lg hover:shadow-xl transition-shadow p-6 border border-pink-100">
      <h3 className="font-semibold text-lg text-gray-900 mb-1">Weight Change</h3>
      <div className="text-xs text-gray-500 mb-3">
        {formatDate(firstEntry.datetime)} to {formatDate(lastEntry.datetime)}
      </div>
      <div className="text-3xl font-bold mb-2 flex items-center">
        <span className={changeColor}>
          {Math.abs(weightChange)} kg {trendIcon}
        </span>
      </div>
      <div className="text-gray-600">
        You've {changeDirection} <span className={changeColor + " font-medium"}>
          {Math.abs(percentChange)}%
        </span> of your weight
      </div>
      <div className="text-sm text-gray-500 mt-2">
        From {startWeight} kg to {endWeight} kg
      </div>
    </div>
  );
};

export default WeightStats;
