import React from 'react';

const termsContent = {
  title: "Terms of Use",
  intro: "Welcome to Peak Health. By using our app, you agree to the following terms and conditions.",
  sections: [
    {
      heading: "1. Acceptance of Terms",
      paragraph: "By accessing and using the Peak Health app, you accept and agree to be bound by the terms and provision of this agreement. In addition, when using this app, you shall be subject to any posted guidelines or rules applicable to such services."
    },
    {
      heading: "2. Changes to Terms",
      paragraph: "Peak Health reserves the right to update or change these terms at any time. Your continued use of the app after any such changes constitutes your acceptance of the new terms."
    },
    {
      heading: "3. User Responsibilities",
      paragraph: "You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password."
    },
    {
      heading: "4. Privacy",
      paragraph: "Your use of the app is also subject to our Privacy Policy. Please review our Privacy Policy, which also governs the app and informs users of our data collection practices."
    },
    {
      heading: "5. Use of the App",
      paragraph: "Peak Health allows you to register using your email and password, and track various health metrics such as glucose levels, medication, exercise, sleep, and food intake. You can view your reports in both table and chart formats."
    },
    {
      heading: "6. Limitation of Liability",
      paragraph: "Peak Health will not be liable for any damages of any kind arising from the use of this app, including, but not limited to direct, indirect, incidental, punitive, and consequential damages."
    },
    {
      heading: "7. Medical Disclaimer",
      paragraph: "The content provided in this app, including glucose tracking, reports, and insights, is for informational purposes only and is not intended as medical advice, diagnosis, or treatment. Users should always consult with their doctor or qualified healthcare provider: (a) Before making any changes to diet, exercise routine, or medication, (b) For interpretation of glucose readings and health data, (c) If experiencing any unusual symptoms or health concerns. This app does not replace professional medical advice, diagnosis, or treatment. Never disregard professional medical advice or delay seeking it because of something you have read or seen in this app."
    },
    {
      heading: "8. Governing Law",
      paragraph: "These terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Peak Health operates, without regard to its conflict of law principles."
    },
    {
      heading: "Contact Us",
      paragraph: "If you have any questions about these Terms, please contact us at info@cloudnext.dev."
    }
  ]
};

function TermsOfUse() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">{termsContent.title}</h1>
      <p className="text-gray-600 mb-4">{termsContent.intro}</p>
      {termsContent.sections.map((section, index) => (
        <div key={index}>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">{section.heading}</h2>
          <p className="text-gray-600 mb-4">{section.paragraph}</p>
        </div>
      ))}
    </div>
  );
}

export default TermsOfUse;
