import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short'
  });
};

const CustomTooltip = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;

  const glucoseData = payload[0].payload;
  const activities = glucoseData.activities || [];
  const readingTime = glucoseData.fullDate.toLocaleTimeString([], { 
    hour: '2-digit', 
    minute: '2-digit' 
  });

  return (
    <div className="bg-white p-4 rounded shadow-lg border border-gray-200">
      <p className="font-medium text-gray-800">{label} at {readingTime}</p>
      <p className="text-cyan-600">Glucose: {glucoseData.glucose} mmol/L</p>
      {activities.length > 0 && (
        <div className="mt-2">
          <p className="font-medium text-gray-700">Recent activities:</p>
          <ul className="text-sm text-gray-600">
            {activities.map((activity, index) => (
              <li key={index}>
                {activity.type.charAt(0).toUpperCase() + activity.type.slice(1)}: {activity.value}
                <span className="text-gray-400 text-xs ml-2">
                  ({new Date(activity.datetime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const GlucoseChart = ({ entries, dateRange }) => {
  const glucoseData = entries
    .filter(entry => entry.type === 'glucose')
    .filter(entry => {
      if (!dateRange.start && !dateRange.end) return true;
      
      const entryDate = new Date(entry.datetime);
      const startDate = dateRange.start ? new Date(dateRange.start) : null;
      const endDate = dateRange.end ? new Date(dateRange.end) : null;
      
      if (startDate) startDate.setHours(0, 0, 0, 0);
      if (endDate) endDate.setHours(23, 59, 59, 999);
      
      if (startDate && entryDate < startDate) return false;
      if (endDate && entryDate > endDate) return false;
      return true;
    })
    .map(entry => {
      const entryDate = new Date(entry.datetime);
      const activities = entries
        .filter(e => e.type !== 'glucose')
        .filter(e => {
          const activityDate = new Date(e.datetime);
          // Get activities from same day before the glucose reading
          // or from previous day if the glucose reading is early in the day
          if (activityDate > entryDate) return false;
          
          const dayDiff = (entryDate - activityDate) / (1000 * 60 * 60 * 24);
          const isEarlyReading = entryDate.getHours() < 10; // Consider readings before 10 AM as early

          return dayDiff <= (isEarlyReading ? 1 : 0); // Include previous day for early readings
        })
        .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
        .slice(0, 5); // Show up to 5 most recent activities

      return {
        fullDate: entryDate,
        date: formatDate(entry.datetime),
        glucose: parseFloat(entry.value),
        activities
      };
    })
    .sort((a, b) => a.fullDate - b.fullDate);

  if (glucoseData.length === 0) {
    return (
      <div className="h-[400px] flex items-center justify-center text-gray-500">
        No glucose data available for the selected period
      </div>
    );
  }

  const minGlucose = Math.floor(Math.min(...glucoseData.map(d => d.glucose)));
  const maxGlucose = Math.ceil(Math.max(...glucoseData.map(d => d.glucose)));
  const lowerBound = Math.max(minGlucose - 2, 1);  // 2 units below lowest reading, but not lower than 4
  const upperBound = Math.min(maxGlucose + 2, 30);  // 2 units above highest reading, but not higher than 9
  const ticks = Array.from(
    { length: upperBound - lowerBound + 1 }, 
    (_, i) => lowerBound + i
  );

  return (
    <div className="h-[400px]">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={glucoseData} margin={{ top: 20, right: 30, left: 0, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date" 
            angle={-45}
            textAnchor="end"
            height={70}
          />
          <YAxis 
            label={{ value: 'mmol/L', angle: -90, position: 'insideLeft' }}
            domain={[lowerBound, upperBound]}
            ticks={ticks}
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="glucose"
            stroke="#0891b2"
            strokeWidth={2}
            dot={{ fill: '#0891b2' }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GlucoseChart;
