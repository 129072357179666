import React from 'react';

const convertToMgDl = (mmol) => (mmol * 18).toFixed(2);

const formatDateForDisplay = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
};

// Change to export the function
export const getFullDateRange = (entries) => {
  if (!entries.length) return { start: '', end: '' };
  const dates = entries.map(e => new Date(e.datetime));
  return {
    start: new Date(Math.min(...dates)).toISOString().split('T')[0],
    end: new Date(Math.max(...dates)).toISOString().split('T')[0]
  };
};

const calculateGlucoseStats = (entries, dateRange) => {
  const glucoseEntries = entries
    .filter(entry => entry.type === 'glucose')
    .filter(entry => {
      if (!dateRange.start && !dateRange.end) return true;
      
      const entryDate = new Date(entry.datetime);
      const startDate = dateRange.start ? new Date(dateRange.start) : null;
      const endDate = dateRange.end ? new Date(dateRange.end) : null;
      
      if (startDate) startDate.setHours(0, 0, 0, 0);
      if (endDate) endDate.setHours(23, 59, 59, 999);
      
      if (startDate && entryDate < startDate) return false;
      if (endDate && entryDate > endDate) return false;
      return true;
    });

  if (glucoseEntries.length === 0) {
    return {
      average: 0,
      count: 0
    };
  }

  const values = glucoseEntries.map(entry => parseFloat(entry.value));
  const sum = values.reduce((acc, val) => acc + val, 0);
  
  return {
    average: (sum / values.length).toFixed(1),
    count: values.length
  };
};

const getDaysBetweenDates = (startDate, endDate) => {
  if (!startDate || !endDate) return null;
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffTime = Math.abs(end - start);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
};

const GlucoseStats = ({ dateRange, entries }) => {
  const stats = calculateGlucoseStats(entries, dateRange);
  const fullRange = getFullDateRange(entries);
  const days = dateRange.start && dateRange.end 
    ? getDaysBetweenDates(dateRange.start, dateRange.end)
    : entries.length > 0 
    ? getDaysBetweenDates(fullRange.start, fullRange.end)
    : null;
  
  const isShowingAllTime = dateRange.start === fullRange.start && 
                          dateRange.end === fullRange.end;
  
  return (
    <div className="bg-white rounded-3xl shadow-lg hover:shadow-xl transition-shadow p-6 border border-blue-100">
      <h3 className="font-semibold text-lg text-gray-900 mb-1">
        Average glucose over {days} days
      </h3>
      {isShowingAllTime && (
        <div className="text-xs text-gray-500 mb-3">
          Showing all time data
        </div>
      )}
      {stats.count > 0 ? (
        <div>
          <div className="text-3xl font-bold text-teal-700 mb-2">
            {stats.average}
            <span className="text-lg font-normal text-teal-600 ml-1">mmol/L</span>
          </div>
          <div className="text-gray-600">
            {convertToMgDl(stats.average)} mg/dL
            <span className="text-sm text-gray-500 ml-2">
              from {stats.count} readings
            </span>
          </div>
        </div>
      ) : (
        <div>
          <p className="text-gray-600 mb-2">
            No glucose readings available
          </p>
        </div>
      )}
    </div>
  );
};

export default GlucoseStats;
