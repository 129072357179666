import React, { useState, useEffect } from 'react';
import Auth from './Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDroplet, faVirusCovid, faNotesMedical, faCircle, faPlay } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

const faqData = [
  {
    question: "Who is this app for?",
    answer: `Anyone can benefit from this app. It is useful if you have pre-diabetes, diabetes, 
      or might get diabetes because of family history or obesity. It is also useful if you are healthy 
      but want to keep your health in check by learning what foods and habits can spike your blood guclose. 
      Remember to talk to a doctor before making changes based on the app's reports.`
  },
  {
    question: "How will this app monitor my blood glucose?",
    answer: `The app helps you keep track of your blood sugar by letting you log your levels, meals, medicines, exercise, and sleep. 
      You'll need a blood sugar meter or a continuous glucose monitor to get readings. Consult with a doctor or qualified health professional before using these devices.`
  },
  {
    question: "Can I export my data?",
    answer: `We're adding a way to export data soon. For now, you can copy the info and put it into a document or spreadsheet on your device.`
  },
  {
    question: "What health reports can I see?",
    answer: `Right now, there are two reports: one shows your past glucose readings and activities in a table, which you can filter by activity, 
      and another shows your blood sugar levels over time in a chart. We'll have more reports soon.`
  }
];

function LandingPage() {
  const [showAuth, setShowAuth] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [currentText, setCurrentText] = useState("Struggling with your weight?");
  const [isVisible, setIsVisible] = useState(true);
  // Add state for video modal
  const [showVideoModal, setShowVideoModal] = useState(false);

  const rotatingTexts = [
    "Struggling with your weight?",
    "Are you having health issues?",
    "Do you want to optimize your health?"
  ];

  useEffect(() => {
    let currentIndex = 0;
    
    const interval = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        currentIndex = (currentIndex + 1) % rotatingTexts.length;
        setCurrentText(rotatingTexts[currentIndex]);
        setIsVisible(true);
      }, 200); // Wait for fade out before changing text
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-teal-100 via-white to-teal-100">
      {/* Enhanced Hero Background with Subtle Pattern */}
      <div className="relative bg-gradient-to-b from-cyan-50 to-transparent">
        {/* Decorative Elements */}
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden opacity-10 pointer-events-none">
          <div className="absolute top-10 left-10 w-64 h-64 rounded-full bg-teal-300 mix-blend-multiply"></div>
          <div className="absolute top-40 right-20 w-48 h-48 rounded-full bg-amber-200 mix-blend-multiply"></div>
          <div className="absolute bottom-10 left-1/4 w-56 h-56 rounded-full bg-cyan-200 mix-blend-multiply"></div>
        </div>
        
        <div className="container mx-auto px-4 pt-16 pb-20">
          {/* Hero Section with enhanced styling */}
          <div className="text-center mb-12 relative z-10 animate-fade-in">
            {/* Rotating Text with Animation */}
            <div className="h-10 mb-4">
              <p className={`text-base md:text-lg italic text-teal-700 max-w-2xl mx-auto leading-relaxed transition-all duration-300 ease-in-out ${isVisible ? 'opacity-100 transform-none' : 'opacity-0 transform -translate-y-2'}`}>
                {currentText}
              </p>
            </div>
            
            {/* Enhanced Title Section with Card-like Background */}
            <div className="max-w-4xl mx-auto bg-white/80 backdrop-blur-sm rounded-3xl px-8 py-10 shadow-xl relative overflow-hidden">
              {/* Subtle Diagonal Stripe */}
              <div className="absolute -right-10 -top-20 w-64 h-80 bg-gradient-to-r from-amber-100/60 to-amber-200/40 rotate-12 rounded-full blur-xl"></div>
              <div className="absolute -left-10 -bottom-20 w-64 h-80 bg-gradient-to-r from-teal-100/60 to-cyan-200/40 -rotate-12 rounded-full blur-xl"></div>
              
              <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-2 relative">
                Take charge of your health with
              </h2>
              <h1 className="text-5xl md:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-teal-600 to-cyan-700 mb-3 leading-tight relative">
                Health Tracker
              </h1>
              <h3 className="text-xl md:text-2xl font-bold text-gray-700 mb-6 relative">
                Log your data<span className="mx-2 inline-block w-2 h-2 bg-amber-300 rounded-full align-middle relative" style={{top: "-1px"}}></span>Master your health
              </h3>
              <p className="text-base md:text-lg text-gray-700 max-w-3xl mx-auto leading-relaxed mb-8 relative">
                Understand how the food you eat and your daily activities impact your weight and blood sugar by logging them in this free and super easy tool.
                It takes less than a minute to get started!
              </p>

              {/* Authentication Buttons with Enhanced Styling */}
              {!showAuth && (
                <div className="flex flex-col justify-center items-center max-w-md mx-auto relative">
                  <button
                    onClick={() => {
                      setIsLogin(false);
                      setShowAuth(true);
                    }}
                    className="px-10 py-3.5 bg-gradient-to-r from-amber-300 to-amber-200 text-gray-700 font-semibold rounded-full hover:from-amber-200 hover:to-amber-100 transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-amber-200/50"
                  >
                    Start now. It's free!
                  </button>
                  
                  <button
                    onClick={() => {
                      setIsLogin(true);
                      setShowAuth(true);
                    }}
                    className="mt-4 text-teal-700 hover:text-teal-500 transition-colors text-sm font-medium hover:underline"
                  >
                    Already have an account? Login here
                  </button>
                </div>
              )}
            </div>
            
            {/* Auth Component with conditional rendering */}
            {showAuth && (
              <div className="max-w-md mx-auto mb-20 mt-4">
                <Auth initialIsLogin={isLogin} onClose={() => setShowAuth(false)} />
              </div>
            )}
            
            {/* Simplified video link - just text with play button */}
            {!showAuth && (
              <div className="mt-16">
                <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-600 to-cyan-700 mb-6">How does it work?</h2>
                <button 
                  className="flex items-center justify-center mx-auto gap-3 py-3 px-6 bg-white rounded-full shadow-md hover:shadow-lg transition-all group"
                  onClick={() => setShowVideoModal(true)}
                >
                  <span className="text-lg font-medium text-gray-700">Watch the demo here</span>
                  <div className="w-10 h-10 flex items-center justify-center rounded-full bg-amber-200 text-gray-800 group-hover:bg-amber-100 group-hover:scale-110 transition-all">
                    <FontAwesomeIcon icon={faPlay} className="ml-0.5" />
                  </div>
                </button>
                
                {/* Updated Screenshots section - one per row */}
                <div className="mt-16 max-w-4xl mx-auto">
                  <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-600 to-cyan-700 mb-10">App Features</h2>
                  
                  <div className="space-y-12">
                    {/* New Screenshot */}
                    <div className="bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden max-w-4xl mx-auto">
                      <h3 className="text-lg font-semibold text-gray-700 p-4 border-b border-gray-100">
                        Easy Data Logging. You can track one or more activities. It's flexible to your needs.
                      </h3>
                      <div className="p-3">
                        <img 
                          src="/images/healthtracker5.png" 
                          alt="Comprehensive health tracking dashboard" 
                          className="rounded-lg w-full object-cover shadow-sm"
                        />
                      </div>
                    </div>

                    {/* Screenshot 1 */}
                    <div className="bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden max-w-4xl mx-auto">
                      <h3 className="text-lg font-semibold text-gray-700 p-4 border-b border-gray-100">
                        A health summary to show how you are doing overall
                      </h3>
                      <div className="p-3">
                        <img 
                          src="/images/healthtracker1.png" 
                          alt="Easy data logging interface" 
                          className="rounded-lg w-full object-cover shadow-sm"
                        />
                      </div>
                    </div>
                    
                    {/* Screenshot 2 */}
                    <div className="bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden max-w-4xl mx-auto">
                      <h3 className="text-lg font-semibold text-gray-700 p-4 border-b border-gray-100">
                        Your historical health data that you can filter by activity and export to a file
                      </h3>
                      <div className="p-3">
                        <img 
                          src="/images/healthtracker2.png"
                          alt="Visual health reports and charts" 
                          className="rounded-lg w-full object-cover shadow-sm"
                        />
                      </div>
                    </div>
                    
                    {/* Screenshot 3 - modified for two images */}
                    <div className="bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden max-w-4xl mx-auto">
                      <h3 className="text-lg font-semibold text-gray-700 p-4 border-b border-gray-100">
                        Charts for a visual overview of your health
                      </h3>
                      <div className="p-3 grid grid-cols-2 gap-3">
                        <img 
                          src="/images/healthtracker3.png" 
                          alt="Multiple health metrics tracking" 
                          className="rounded-lg w-full object-cover shadow-sm"
                        />
                        <img 
                          src="/images/healthtracker4.png" 
                          alt="Additional health metrics view" 
                          className="rounded-lg w-full object-cover shadow-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Video Modal */}
      {showVideoModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4" onClick={() => setShowVideoModal(false)}>
          <div className="relative max-w-4xl w-full mx-auto" onClick={(e) => e.stopPropagation()}>
            <button 
              className="absolute -top-10 right-0 text-white hover:text-gray-300 transition-colors"
              onClick={() => setShowVideoModal(false)}
            >
              Close
            </button>
            <div className="bg-black rounded-lg overflow-hidden shadow-2xl">
              <video 
                controls
                className="w-full h-auto"
                autoPlay
                preload="auto"
              >
                <source src="/demo-video.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}

      <div className="container mx-auto px-4 pb-16">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-600 to-cyan-700 mb-4">
            Why should you monitor your blood sugar?
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
          Your blood sugar level is one of the most important metrics of health.
          High glucose levels over a long period of time, according to studies, are associated not just with diabetes but a host of many other health conditions.
          </p>
        </div>
        <div className="grid md:grid-cols-3 gap-16 md:gap-8 max-w-6xl mx-auto px-4 mt-16">
          <div className="relative bg-white p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-16 h-16 bg-amber-50 rounded-full shadow-md flex items-center justify-center">
              <span className="text-3xl text-teal-700">
              <FontAwesomeIcon icon={faDroplet} />
              </span>
            </div>
            <p className="text-gray-700 mb-4 mt-6">
              Elevated blood sugar levels are a sign of insulin resistance which can lead to type 2 diabetes and obesity.
            </p>
            <p className="text-teal-700 text-xs">Find out more about <a href="https://www.mayoclinic.org/diseases-conditions/diabetes/diagnosis-treatment/drc-20371451" className="font-semibold">how diabetes is diagnosed.</a>  </p>
          </div>
          <div className="relative bg-white p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-16 h-16 bg-amber-50 rounded-full shadow-md flex items-center justify-center">
              <span className="text-3xl text-teal-700">
              <FontAwesomeIcon icon={faVirusCovid} />
              </span>
            </div>
            <p className="text-gray-700 mb-4 mt-6">
            Diabetes is strongly linked to obesity and other chronic diseases, such as heart disease.
            </p>
            <p className="text-teal-700 text-xs">
              Read studies that associate diabetes with 
              <a href="https://www.heart.org/en/health-topics/diabetes/diabetes-complications-and-risks/cardiovascular-disease--diabetes" className ="font-semibold"> heart disease</a>, 
              <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5428707/" className="font-semibold"> fatty liver</a>, and 
              <a href="https://www.niddk.nih.gov/health-information/diabetes/overview/preventing-problems/diabetic-kidney-disease" className="font-semibold"> kidney disease</a>.</p>
          </div>
          <div className="relative bg-white p-8 rounded-3xl shadow-lg hover:shadow-xl transition-shadow">
            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-16 h-16 bg-amber-50 rounded-full shadow-md flex items-center justify-center">
              <span className="text-3xl text-teal-700">
              <FontAwesomeIcon icon={faNotesMedical} />
              </span>
            </div>
            <p className="text-gray-700 mb-4 mt-6">
            Each person is different so it's important to track how the food you eat and your other habits affect your blood sugar levels.
            </p>
            <p className="text-teal-700 text-xs">
                Understand 
                <a href="https://www.otsuka.co.jp/en/health-and-illness/glycemic-index/glucose-level/" className="font-semibold"> how food affects your blood sugar levels.
              </a>
            </p>
          </div>
        </div>

        {/* Benefits Section with enhanced visual hierarchy */}
        {/* <div className="mt-24 text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-12 relative inline-block">
            Why Choose Peak Health?
            <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 to-blue-700 transform -translate-y-2"></div>
          </h2>
          <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
            <BenefitItem  
              title="Comprehensive Tracking"
              description="Monitor not just glucose, but also exercise, sleep, and nutrition"
            />
            <BenefitItem
              title="Data Security"
              description="Your health data is encrypted and securely stored"
            />
            <BenefitItem
              title="Easy to Use"
              description="Intuitive interface designed for users of all ages"
            />
            <BenefitItem
              title="Better Health Decisions"
              description="Make informed choices based on your personal health data"
            />
          </div>
        </div> */}

        {/* Add FAQ section before the footer */}
        <div className="mt-24 max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">
            Frequently Asked Questions
          </h2>
          <div className="space-y-6">
            {faqData.map((faq, index) => (
              <div 
                key={index}
                className="bg-white p-6 rounded-2xl shadow-md hover:shadow-lg transition-shadow"
              >
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  {faq.question}
                </h3>
                <p className="text-gray-600">
                  {faq.answer}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-16 py-8 bg-gray-50">
  <div className="container mx-auto px-4">
    <div className="max-w-4xl mx-auto text-sm text-gray-600 space-y-4">
      <p className="font-medium">Medical Disclaimer:</p>
      <p>
        The content provided in this app, including glucose tracking, reports, and insights, 
        is for informational purposes only and is not intended as medical advice, diagnosis, 
        or treatment. Always consult with your doctor or qualified healthcare provider:
      </p>
      <ul className="list-disc pl-5 space-y-2">
        <li>Before making any changes to your diet, exercise routine, or medication</li>
        <li>For interpretation of your glucose readings and health data</li>
        <li>If you experience any unusual symptoms or health concerns</li>
      </ul>
      <p>
        This app does not replace professional medical advice, diagnosis, or treatment. 
        Never disregard professional medical advice or delay seeking it because of 
        something you have read or seen in this app.
      </p>
    </div>
  </div>
</div>

        {/* Enhanced Footer */}
        <footer className="mt-24 text-center text-gray-600 text-sm border-t border-gray-200 pt-8">
          <div className="flex justify-center space-x-4">
            <p>© 2025 Peak Health. All rights reserved.</p>
            {/* <Link to="/terms-of-use" target="_blank" className="text-teal-700 hover:text-teal-500 transition-colors">
              Terms of Use
            </Link>
            <Link to="/privacy-policy" target="_blank" className="text-teal-700 hover:text-teal-500 transition-colors">
              Privacy Policy
            </Link> */}
          </div>
        </footer>
      </div>
    </div>
  );
}

function FeatureCard({ icon, title, description }) {
  return (
    <div className="bg-white p-8 rounded-xl shadow-lg border border-gray-100 hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1">
      <div className="text-4xl mb-5 bg-blue-50 w-14 h-14 rounded-full flex items-center justify-center mx-auto">{icon}</div>
      <h3 className="text-lg font-bold text-gray-800 mb-3">{title}</h3>
      <p className="text-gray-600 leading-relaxed">{description}</p>
    </div>
  );
}

function BenefitItem({ title, description }) {
  return (
    <div className="bg-gradient-to-br from-blue-50 to-white p-8 rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300">
      <h3 className="text-xl font-bold text-blue-900 mb-3">{title}</h3>
      <p className="text-gray-700 leading-relaxed">{description}</p>
    </div>
  );
}

export default LandingPage;
