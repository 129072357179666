import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short'
  });
};

const CustomTooltip = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;

  const weightData = payload[0].payload;
  const readingTime = weightData.fullDate.toLocaleTimeString([], { 
    hour: '2-digit', 
    minute: '2-digit' 
  });

  return (
    <div className="bg-white p-4 rounded shadow-lg border border-gray-200">
      <p className="font-medium text-gray-800">{label} at {readingTime}</p>
      <p className="text-cyan-600">Weight: {weightData.weight} kg</p>
    </div>
  );
};

const WeightChart = ({ entries, dateRange }) => {
  const weightData = entries
    .filter(entry => entry.type === 'weight')
    .filter(entry => {
      if (!dateRange.start && !dateRange.end) return true;
      
      const entryDate = new Date(entry.datetime);
      const startDate = dateRange.start ? new Date(dateRange.start) : null;
      const endDate = dateRange.end ? new Date(dateRange.end) : null;
      
      if (startDate) startDate.setHours(0, 0, 0, 0);
      if (endDate) endDate.setHours(23, 59, 59, 999);
      
      if (startDate && entryDate < startDate) return false;
      if (endDate && entryDate > endDate) return false;
      return true;
    })
    .map(entry => {
      const entryDate = new Date(entry.datetime);

      return {
        fullDate: entryDate,
        date: formatDate(entry.datetime),
        weight: parseFloat(entry.value)
      };
    })
    .sort((a, b) => a.fullDate - b.fullDate);

  if (weightData.length === 0) {
    return (
      <div className="h-[400px] flex items-center justify-center text-gray-500">
        No weight data available for the selected period
      </div>
    );
  }

  const minWeight = Math.floor(Math.min(...weightData.map(d => d.weight)));
  const maxWeight = Math.ceil(Math.max(...weightData.map(d => d.weight)));
  const lowerBound = Math.max(minWeight - 2, 1);
  const upperBound = Math.min(maxWeight + 2, 200);
  const ticks = Array.from(
    { length: upperBound - lowerBound + 1 }, 
    (_, i) => lowerBound + i
  );

  return (
    <div className="h-[400px]">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={weightData} margin={{ top: 20, right: 30, left: 0, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date" 
            angle={-45}
            textAnchor="end"
            height={70}
          />
          <YAxis 
            label={{ value: 'kg', angle: -90, position: 'insideLeft' }}
            domain={[lowerBound, upperBound]}
            ticks={ticks}
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="weight"
            stroke="#82ca9d"
            strokeWidth={2}
            dot={{ fill: '#82ca9d' }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WeightChart;
