/**
 * Format date for display
 * 
 * @param {Date} date - The date to format
 * @returns {string} Formatted date string
 */
export const formatDateForDisplay = (date) => {
  const isMobile = window.innerWidth <= 640;
  
  if (isMobile) {
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    });
  }
  
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',  // This will show month as 'Dec' instead of '12'
    year: 'numeric'
  });
};

/**
 * Convert glucose reading between mmol/L and mg/dL units
 * 
 * @param {number} mmol - Value in mmol/L
 * @returns {string} Value in mg/dL
 */
export const convertToMgDl = (mmol) => (mmol * 18).toFixed(0);

/**
 * Convert glucose reading between mg/dL and mmol/L units
 * 
 * @param {number} mgdl - Value in mg/dL
 * @returns {string} Value in mmol/L
 */
export const convertToMmol = (mgdl) => (mgdl / 18).toFixed(1);

/**
 * Format activity value for display based on its type
 * 
 * @param {string} type - The type of activity (glucose, sleep, weight, etc.)
 * @param {any} value - The raw value
 * @param {string} unit - The unit (for glucose readings)
 * @returns {string} Formatted value for display
 */
export const formatActivityValue = (type, value, unit) => {
  if (!value) return '';
  
  switch (type.toLowerCase()) {
    case 'glucose':
      const mmolValue = unit === 'mmol' ? value : convertToMmol(value);
      const mgdlValue = unit === 'mmol' ? convertToMgDl(value) : value;
      return `${mmolValue} mmol/L (${mgdlValue} mg/dL)`;
      
    case 'sleep':
      return `${value} hours`;
      
    case 'weight':
      return `${value} kg`;
      
    default:
      return value;
  }
};
